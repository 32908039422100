@import "~normalize.css";
@import "~antd/dist/antd.css";
@import "~nprogress/nprogress.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "./gloable.css";

/* 样式的重置 */
body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #666;
}

a:hover {
  color: #666;
  text-decoration: underline;
}

i,
em {
  font-style: normal;
}

input,
textarea,
button,
select,
a {
  outline: none;
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: none;
  vertical-align: middle;
}

body,
textarea,
select,
input,
button {
  font-size: 12px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f5f5f5;
}

.text-nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrap-v1 {
  width: 1100px;
  margin: 0 auto;
}

.wrap-v2 {
  width: 980px;
  margin: 0 auto;
}
