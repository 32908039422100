.site-layout .site-layout-background {
  background: #fff;
}

#root,
.ant-layout,
.height-all {
  height: 100%;
}

.ant-layout-sider {
  height: 100%;
  min-width: 250px;
}

/* 滚动条 */
::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  background-color: #1890ff;
}
::-webkit-scrollbar-track {
  background-color: #ddd;
}
